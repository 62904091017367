<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body p-2">
               <div class="d-flex justify-content-end">
                  <div class="wpx-200 me-2">
                     <v-select title="Desktop" :options="['Noticia', 'Produto', 'Formulário']" v-model="pesquisa.tipo" placeholder="Selecione um tipo" @input="buscarPostagens(0)" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo" @change="buscarPostagens(0)">
                        <option v-for="(option, index) in ['Noticia', 'Produto', 'Formulário']" :key="index" :value="option">{{ option }}</option>
                     </select>
                  </div>
                  <div class="w-max-content">
                     <button class="btn btn-primary" @click="abrirPostagem(null)"><i class="far fa-plus font-12"></i><span class="d-none d-sm-inline ms-1"> Adicionar</span></button>
                  </div>
               </div>
            </div>
         </div>

         <div class="cards-150 cards-sm-250">
            <noticia v-for="(noticia, index) in pesquisa.resultado" :key="index" :index="index" :noticia="noticia" @editar="abrirPostagem($event)" />
         </div>
         <div class="col-12 px-1 my-5 text-center" v-if="pesquisa.resultado.length == 0">Nenhum resultado encontrado</div>
         <div class="col-12 px-1 mt-3 mb-2 text-center" v-else-if="pesquisa.resultado.length % 50 == 0">
            <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarPostagens(null)">Ver mais</button>
         </div>

         <!-- modalPostagem -->
         <div class="modal fade" id="modalPostagem" aria-labelledby="modalPostagemLabel" data-bs-backdrop="static" aria-hidden="true">
            <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
               <div class="modal-content h-100">
                  <div class="modal-header">
                     <h5 class="modal-title" id="modalPostagemLabel" v-if="postagem.id != null">Editar postagem</h5>
                     <h5 class="modal-title" id="modalPostagemLabel" v-else>Adicionar postagem</h5>
                     <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                     <div class="card mb-2">
                        <div class="card-body p-12">
                           <ul class="nav nav-pills" id="pills-tab" role="tablist">
                              <li class="nav-item col" role="presentation">
                                 <button class="nav-link m-0 active" id="pills-basico-tab" data-bs-toggle="pill" data-bs-target="#pills-basico" type="button" role="tab" aria-controls="pills-basico" aria-selected="true">
                                    <i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Info. básica</span>
                                 </button>
                              </li>
                              <li class="nav-item col" role="presentation" v-if="postagem.tipo != 'Formulário'">
                                 <button class="nav-link m-0" id="pills-corpo-tab" data-bs-toggle="pill" data-bs-target="#pills-corpo" type="button" role="tab" aria-controls="pills-corpo" aria-selected="false">
                                    <i class="far fa-align-justify font-13"></i><span class="ms-1 d-none d-sm-inline"> Corpo da notícia</span>
                                 </button>
                              </li>
                           </ul>
                        </div>
                     </div>

                     <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-basico" role="tabpanel" aria-labelledby="pills-basico-tab">
                           <div class="card">
                              <div class="card-body pb-0">
                                 <div class="row m-0">
                                    <div class="col-md-6 px-0 mb-3">
                                       <div class="row m-0">
                                          <div class="col-12 px-1 mb-1">
                                             <label><i class="far fa-stream color-theme me-1"></i> Tipo</label>
                                          </div>
                                          <div class="col-6 px-1">
                                             <div class="form-check">
                                                <input class="form-check-input cursor-pointer" type="radio" name="tipoPostagem" id="tipoPostagem1" @click="postagem.tipo = 'Noticia';" :checked="postagem.tipo == 'Noticia'">
                                                <label class="form-check-label cursor-pointer" for="tipoPostagem1">&nbsp;Notícia</label>
                                             </div>
                                          </div>
                                          <div class="col-6 px-1">
                                             <div class="form-check">
                                                <input class="form-check-input cursor-pointer" type="radio" name="tipoPostagem" id="tipoPostagem2" @click="postagem.tipo = 'Produto';" :checked="postagem.tipo == 'Produto'">
                                                <label class="form-check-label cursor-pointer" for="tipoPostagem2">&nbsp;Produto</label>
                                             </div>
                                          </div>
                                          <!-- <div class="col-4 px-1">
                                             <div class="form-check">
                                                <input class="form-check-input cursor-pointer" type="radio" name="tipoPostagem" id="tipoPostagem3" @click="postagem.tipo = 'Formulário';" :checked="postagem.tipo == 'Formulário'">
                                                <label class="form-check-label cursor-pointer" for="tipoPostagem3">&nbsp;Formulário</label>
                                             </div>
                                          </div> -->
                                       </div>
                                    </div>
                                    <div class="col-md-6 px-1 mb-3" v-if="postagem.tipo == 'Noticia' || postagem.tipo == 'Formulário'">
                                       <label><i class="far fa-heading color-theme me-1"></i> Título</label>
                                       <input type="text" class="form-control" v-model="postagem.titulo" maxlength="250" />
                                    </div>
                                    <div class="col-md-6 px-1 mb-3" v-else-if="postagem.tipo == 'Produto'">
                                       <label><i class="far fa-barcode color-theme me-1"></i> Código</label>
                                       <input type="text" class="form-control" v-model="postagem.tags" maxlength="250" />
                                    </div>
                                    <div class="col-sm-6 px-1 mb-3" v-if="postagem.tipo == 'Noticia'">
                                       <label><i class="far fa-tags color-theme me-1"></i> Produtos relacionados</label>
                                       <textarea class="form-control" v-model="postagem.tags" maxlength="250"></textarea>
                                    </div>
                                    <div class="w-max-content px-1 mb-3 align-self-end" v-if="(postagem.tipo == 'Noticia' || postagem.tipo == 'Formulário') && postagem.banner != null">
                                       <div class="results__finnaly">
                                          <label class="d-block" v-if="postagem.tipo == 'Noticia'"><i class="far fa-image color-theme me-1"></i> Banner <small>(3x2)</small></label>
                                          <label class="d-block" v-else><i class="far fa-image color-theme me-1"></i> Background</label>
                                          <img :src="postagem.banner" />
                                       </div>
                                    </div>
                                    <div class="px-1 mb-3 align-self-end" v-show="postagem.tipo == 'Noticia' || postagem.tipo == 'Formulário'" :class=" postagem.tipo == 'Noticia' && postagem.banner == null ? 'col-sm-6' : 'col'">
                                       <div class="form-group file-area">
                                          <label v-if="postagem.tipo == 'Noticia' && postagem.banner == null"><i class="far fa-image color-theme me-1"></i> Banner <small>(3x2)</small></label>
                                          <label v-if="postagem.tipo == 'Formulário' && postagem.banner == null"><i class="far fa-image color-theme me-1"></i> Background</label>
                                          <input type="file" ref="anexoHeader" accept=".jpg,.jpeg,.png,.webp" @change="uploadArquivo" @drop="uploadArquivo" />
                                          <div class="file-dummy">
                                             <div class="default">Escolher banner</div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-12 px-1 mb-3" v-show="postagem.tipo == 'Noticia' || postagem.tipo == 'Formulário'">
                                       <label><i class="far fa-quote-right color-theme me-1"></i> Resumo</label>
                                       <JoditEditor :value="postagem.descricao" @attValue="postagem.descricao = $event" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="tab-pane fade" id="pills-corpo" role="tabpanel" aria-labelledby="pills-corpo-tab">
                           <div class="card">
                              <div class="card-body">
                                 <JoditEditor :value="postagem.conteudo" @attValue="postagem.conteudo = $event" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-primary" @click="salvarPostagem">Salvar</button>
                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import noticia from '@/components/admin/blog/Noticia.vue'
import JoditEditor from '@/components/admin/EditorHTML.vue'

export default {
   name: 'Blog',
   data : function () {
      return {
         pesquisa: {'tipo': 'Noticia', 'pagina': 0, 'resultado': []},
         postagem: {'id': null, 'tipo': 'Noticia', 'titulo': '', 'descricao': '', 'banner': null, 'tags': [], 'conteudo': ''},
      }
   },
   computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario,
			urlRest: state => state.urlRest
		})
	},
   components: {
		noticia, JoditEditor
	},
   methods: {
      buscarPostagens : function (pagina) {
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.pagina = pagina == null ? ++this.pesquisa.pagina : pagina

			this.$axios({
				method: 'get',
				url: this.urlRest + 'adminService/getNoticias',
				params: {
					tipo: this.pesquisa.tipo,
					pagina: this.pesquisa.pagina
				}
			}).then(response => {
            if (this.pesquisa.pagina == 0) {
               this.pesquisa.resultado = []
            }

            response.data.forEach(p => {
               this.pesquisa.resultado.push(p)
            });
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogarAdmin')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      abrirPostagem : function (postagem) {
         if (postagem) {
            this.buscarConteudoNoticia(postagem)
         } else {
            this.postagem = {'id': null, 'tipo': 'Noticia', 'titulo': '', 'descricao': '', 'banner': null, 'tags': [], 'conteudo': ''}
         }

         setTimeout(() => {
            this.$refs.anexoHeader.value = null;
            $('#modalPostagem').modal('show')
         }, 250);
      },
      buscarConteudoNoticia : function (postagem) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'adminService/getNoticiaSessoes',
				params: {
					id: postagem.id
				}
			}).then(response => {
            let postagemCopia = JSON.parse(JSON.stringify(postagem))

            this.postagem = {
               'id': postagemCopia.id, 
               'tipo': postagemCopia.tipo, 
               'titulo': postagemCopia.titulo, 
               'descricao': postagemCopia.descricao, 
               'banner': postagemCopia.banner, 
               'tags': postagemCopia.tags, 
               'conteudo': response.data
            }
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogarAdmin')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      salvarPostagem : function () {
			if (this.postagem.tipo != 'Produto' && (this.postagem.titulo == null || String(this.postagem.titulo).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Título inválido!'
            })

            return
			} else if (this.postagem.tipo == 'Produto' && (this.postagem.tags == null || String(this.postagem.tags).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Código inválido!'
            })

            return
			} else if (this.postagem.tipo != 'Formulário' && (this.postagem.conteudo == null || String(this.postagem.conteudo).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Adicione o conteúdo!'
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
            method: 'post',
            url: this.urlRest +'adminService/saveNoticia',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.postagem

         }).then(() => {
				this.buscarPostagens(0)
				$('#modalPostagem').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: 'Postagem salva!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogarAdmin')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Código do produto não encontrado!'
                  });
               } else if (error.response.status == 409) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Produto com página já cadastrada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
      uploadArquivo : function () {
         let dataForm = new FormData();
         this.$store.dispatch('alternarTelaCarregamento', true)

         for (let file of this.$refs.anexoHeader.files) {
            dataForm.append('fileAdm', file, String(file.name).replace(/[^\w\s.]/gi, ''));
         }

         this.$axios({
            method: 'post',
            url: this.urlRest +'adminService/uploadArquivo',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then((response) => {
            this.postagem.banner = response.data[0].url

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   },
   mounted() {
      this.buscarPostagens(0)
	}
}

</script>

<style scoped>

.modal .file-area input[type="file"] {
   position: absolute;
   top: 0;
   right: 0;
   min-width: 100%;
   min-height: 100%;
   font-size: 100px;
   text-align: right;
   filter: alpha(opacity=0);
   opacity: 0;
   outline: none;
   background: white;
   cursor: inherit;
   display: block;
}

.modal textarea {
   height: 85px;
}

</style>